// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cases-boost-str-index-en-js": () => import("./../src/pages/cases/boost-str/index.en.js" /* webpackChunkName: "component---src-pages-cases-boost-str-index-en-js" */),
  "component---src-pages-cases-boost-str-index-ru-js": () => import("./../src/pages/cases/boost-str/index.ru.js" /* webpackChunkName: "component---src-pages-cases-boost-str-index-ru-js" */),
  "component---src-pages-cases-deliver-more-campaigns-index-en-js": () => import("./../src/pages/cases/deliver-more-campaigns/index.en.js" /* webpackChunkName: "component---src-pages-cases-deliver-more-campaigns-index-en-js" */),
  "component---src-pages-cases-deliver-more-campaigns-index-ru-js": () => import("./../src/pages/cases/deliver-more-campaigns/index.ru.js" /* webpackChunkName: "component---src-pages-cases-deliver-more-campaigns-index-ru-js" */),
  "component---src-pages-cases-increase-online-advertising-index-en-js": () => import("./../src/pages/cases/increase-online-advertising/index.en.js" /* webpackChunkName: "component---src-pages-cases-increase-online-advertising-index-en-js" */),
  "component---src-pages-cases-increase-online-advertising-index-ru-js": () => import("./../src/pages/cases/increase-online-advertising/index.ru.js" /* webpackChunkName: "component---src-pages-cases-increase-online-advertising-index-ru-js" */),
  "component---src-pages-cases-index-en-js": () => import("./../src/pages/cases/index.en.js" /* webpackChunkName: "component---src-pages-cases-index-en-js" */),
  "component---src-pages-cases-index-ru-js": () => import("./../src/pages/cases/index.ru.js" /* webpackChunkName: "component---src-pages-cases-index-ru-js" */),
  "component---src-pages-cases-reducing-unfulfilled-contracts-index-en-js": () => import("./../src/pages/cases/reducing-unfulfilled-contracts/index.en.js" /* webpackChunkName: "component---src-pages-cases-reducing-unfulfilled-contracts-index-en-js" */),
  "component---src-pages-cases-reducing-unfulfilled-contracts-index-ru-js": () => import("./../src/pages/cases/reducing-unfulfilled-contracts/index.ru.js" /* webpackChunkName: "component---src-pages-cases-reducing-unfulfilled-contracts-index-ru-js" */),
  "component---src-pages-cases-white-label-forecasting-index-en-js": () => import("./../src/pages/cases/white-label-forecasting/index.en.js" /* webpackChunkName: "component---src-pages-cases-white-label-forecasting-index-en-js" */),
  "component---src-pages-cases-white-label-forecasting-index-ru-js": () => import("./../src/pages/cases/white-label-forecasting/index.ru.js" /* webpackChunkName: "component---src-pages-cases-white-label-forecasting-index-ru-js" */),
  "component---src-pages-forecasting-en-js": () => import("./../src/pages/forecasting.en.js" /* webpackChunkName: "component---src-pages-forecasting-en-js" */),
  "component---src-pages-forecasting-ru-js": () => import("./../src/pages/forecasting.ru.js" /* webpackChunkName: "component---src-pages-forecasting-ru-js" */),
  "component---src-pages-index-en-js": () => import("./../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-ru-js": () => import("./../src/pages/index.ru.js" /* webpackChunkName: "component---src-pages-index-ru-js" */),
  "component---src-pages-monitoring-en-js": () => import("./../src/pages/monitoring.en.js" /* webpackChunkName: "component---src-pages-monitoring-en-js" */),
  "component---src-pages-monitoring-ru-js": () => import("./../src/pages/monitoring.ru.js" /* webpackChunkName: "component---src-pages-monitoring-ru-js" */),
  "component---src-pages-yield-management-en-js": () => import("./../src/pages/yield-management.en.js" /* webpackChunkName: "component---src-pages-yield-management-en-js" */),
  "component---src-pages-yield-management-ru-js": () => import("./../src/pages/yield-management.ru.js" /* webpackChunkName: "component---src-pages-yield-management-ru-js" */),
  "component---src-templates-plain-text-page-plain-text-page-js": () => import("./../src/templates/plain-text-page/plain-text-page.js" /* webpackChunkName: "component---src-templates-plain-text-page-plain-text-page-js" */)
}

